<template>
<!-- 买料定价数据 -->
  <div class="buyPrice">
        <van-pull-refresh v-model="refreshing" style="min-height: 100vh;"  @refresh="onRefresh">
            <template v-if="ishow">
                <van-empty
                       class="custom-image"
                       :image="require('@/assets/images/pic_no information@2x.png')"
                       description="暂无数据!"
                     />
            </template>
             <van-list
               v-model="loading"
               :finished="finished"
               finished-text="没有更多了"
               @load="onLoad"
               v-if="!ishow"
             >
               <div class="cell" v-for="(item,index) in list" :key="index">
                   <div class="line"></div>
                   <div class="order">
                    <div class="flex-between">
                      <div class="flex">
                        <div class="icon" v-if="item.businessType == '0' &&item.status == 'WAIT_FETCH'">
                                <input type="checkbox" class="checkBox" name="checkBoxTest" :value="item"  v-model="checkList" @change="checkBox">
                          </div>
                          <h3> {{item.goods[0].name}}</h3>
                      </div>

                       <div class="commonBorder ashBorder" :class="{
                        greenBorder: item.status == 'DONE' ||item.status =='BUY_VIOLATE'?true:false,
                        yellowBorder:item.status == 'WAIT_PAYED' || item.status == 'WAIT_FETCH' || item.status == 'WAIT_FETCH_AUDIT' ||item.status =='WAIT_FETCH_FINISHED'?true:false
                        }">
                           {{item.status =="SUSPENSE"?'待审核'
                           :item.status == "WAIT_FETCH_AUDIT"?'取料审核中'
                           : item.status == "WAIT_FETCH"?'待取料'
                           :item.status == "DONE" ||item.status =="BUY_VIOLATE"?'已完成'
                           : item.status == "WAIT_FETCH_FINISHED"?'取料中'
                           :item.status == "WAIT_PAYED" ?'待付款'
                           :item.status == "UNDELIVERED" ?'未交付'
                           :item.status == "RECEIVE" ?'已交付'
                           :item.status == "CANCEL" ?'已取消'
                           :'未通过' }}</div>
                   </div>
                   <div class="info">
                     <div class="weight flex padd"  v-if="item.hasOwnProperty('businessType')">
                           <div class="size colore1">业务类型</div>
                           <div class="size marginLeft color7" style="marginLeft:6px" >{{item.businessType == '0'?'近期提料':item.businessType == '3'? '延期小克重提料' :'延期提料'}}</div>
                       </div>
                       <div class="weight flex padd">
                           <div class="size colore1">克重（克）</div>
                           <div class="size marginLeft color7" style="marginLeft:6px">&nbsp;{{item.goods[0].weight.toFixed(2)}}克</div>
                       </div>
                       <div class="price flex padd">
                           <div class="size colore1">单价（元/克）</div>
                           <div class="size marginLeft color7" style="marginLeft:6px">&nbsp; {{item.goods[0].price.toFixed(item.goods[0].name.includes("白银") ? 3 : 2)}}元/克</div>
                       </div>
                       <div class="time flex padd">
                           <div class="size colore1">下单时间</div>
                           <div class="size marginLeft color7" style="marginLeft:6px">&nbsp;{{item.createTime | formatDate_}}</div>
                       </div>
                   </div>
                   <div class="flex-between">
                       <div class="orderInfo colore1 van-ellipsis">订单号 : {{item.oid}}</div>
                       <div class="xiangqing" @click="TobuyOrderParticulars(item.oid)">详情 <div class="icon"></div></div>
                   </div>
                   </div>
               </div>
             </van-list>
        </van-pull-refresh>
        <van-dialog v-model="showBuy" :show-confirm-button="false" get-container="#app">
             <van-radio-group v-model="myTakeRadio" >
            <div class="takeMode">
                <div class="input">
                        <van-radio icon-size="12px"  name="myTake" ></van-radio>
                </div>
                <van-cell is-link @click="PickUpMethod('myTake')" :border="false">
                     <!-- 使用 title 插槽来自定义标题 -->
                     <template #title>
                            <span class="custom-title">到店自取</span>
                            <span>(请选择取货中心)</span>
                    </template>

                </van-cell>
                <div class="addres flex">
                    <div><van-icon name="location" /></div>
                     <div>
                        <p style="fontSize:14px">{{defPickUpCenter.company}}</p>
                        <p style="fontSize:14px">{{defPickUpCenter.preAddr}}</p>
                     </div>
                </div>
            </div>
            <div class="takeMode">
                <div class="input">
                        <van-radio icon-size="12px" name="express"></van-radio>
                </div>
                <van-cell is-link @click="PickUpMethod('express')" :border="false">
                     <!-- 使用 title 插槽来自定义标题 -->
                     <template #title>
                            <span class="custom-title">快递到家</span>
                            <span>(请选择收货地址)</span>
                    </template>

                </van-cell>
                <div class="addres flex goHome">
                    <div><van-icon name="location" /></div>
                     <div style="fontSize:14px">{{isAddress?ReceivingGoods.preAddr +ReceivingGoods.address:'请选择地址'}}</div>
                </div>
            </div>
            <div class="box">
                <van-cell-group :border="false" >
                  <van-field v-model="beiZhuVal" label="备注" placeholder="请尽快发货" :center="true"   input-align="right"/>
                </van-cell-group>
            </div>
             </van-radio-group>
          <div class="flex_between popbtn">
            <div class="gocer closebtn" @click="close" >取消</div>
            <div class="gocer confirmbtn" @click="confirm">确定</div>
          </div>
        </van-dialog>
        <van-popup v-model="addresShow" position="bottom" :style="{ height: '30%' }" :close-on-click-overlay=false lazy-render @click-overlay="overPop" get-container="#app">
          <div class="title">选择检测中心</div>
         <van-radio-group v-model="radio" >
               <div class="" v-for="(item,index) in Addres" :key="item.id" :class="{bg:index == radio,text:true}">
                <van-radio :name="index" icon-size="12px" @click="radioClose(item)">
                  <div class="flex-between">
                    <h4>{{item.company}}</h4>
                     <span>{{item.phone}}</span>
                    </div>
                  <p>{{item.preAddr + item.address}}</p>
              </van-radio>

               </div>
          </van-radio-group>
        </van-popup>
  </div>
</template>

<script>
import *as api from '@/utils/api'
export default {
  inject: ["reload"],
  props:['activeName'],
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isAddress:false,
      refreshing: false,
      addresShow:false, //取货中心地址弹窗
      page:0,
      size:4,
     //  复选框选中的数据
      checkList:[],
      orderList:[],
      ishow:false,
      showBuy:false,
      beiZhuVal:'',
      Addres:[],//默认地址
      ReceivingGoods:{},//收货地址
      defPickUpCenter:{}, //默认取货中心地址
      radio:'2',
      myTakeRadio:'myTake'
    };
  },
  activated() {
        if (this.$route.query.addres) {
            this.isAddress = true
            this.ReceivingGoods =JSON.parse(localStorage.getItem('address'))
        }
        else{
            this.onRefresh()
        }
  },
  methods: {
    // 取消按钮事件
    close(){
         this.beiZhuVal = ''
        this.showBuy = false


    },
    // 确定按钮事件
async    confirm(){
            this.showBuy = false
    try{
            let res
        //    判断是到店自取或快递到家
            if (this.myTakeRadio == 'myTake') {
                // 到店自取
                // 发请求获取到店自取数据信息
                 res = await api.getOrderReclaiming({
                // 取货类型,EXPRESS-快递, PLACE-到店取货
                type:'PLACE',
                orderList:this.orderList,
                // 备注
                remark:this.beiZhuVal?'请尽快准备货':this.beiZhuVal,
                // 快递用户地址Id, PLACE时必须
                systemAddressId:this.defPickUpCenter.id
            })
                // 保存到vuex
            }else{
                // 快递到家
                if ( !this.ReceivingGoods.preAddr) {
                        return this.$toast('请选择收货地址'),!1
                }
                // 发请求获取快递到家数据信息
                 res = await api.getOrderReclaiming({
                // 取货类型,EXPRESS-快递, PLACE-到店取货
                type:'EXPRESS',
                orderList:this.orderList,
                 // 备注
                remark:this.beiZhuVal == ''?'请尽快准备货':this.beiZhuVal,
                // 快递用户地址Id, EXPRESS时必须
                addressId:this.ReceivingGoods.id
            })
            }
            this.beiZhuVal = ''
            this.checkList = []
            this.orderList = []
             this.$emit('getData',{weight:0,money:0,orderList:this.orderList})
            this.$store.dispatch('getAsyncOrderInfo',res)
            localStorage.setItem('buyOrderInfo',JSON.stringify(res))
            this.$router.push({
                name:'carryApply',
            })
            // console.log('confirm',res);

    }catch{
        this.showBuy = false

    }
    },
    // 取货中心地址弹窗 按钮
    radioClose(item){
            this.addresShow = false
            this.defPickUpCenter = item
    },
        // 点击遮罩层触发
    overPop(){
            this.addresShow = false
    },
    PickUpMethod(item){
            // /myTake自取     express 快递
            if (item == 'myTake') {
                this.addresShow = true
            }
            if (item == 'express') {
                this.$router.push({
                    name:'address',
                    query:{type:'buyOrder'}
                })

            }
    },
        // 取料按钮事件
  async  confirmTakeMaterial(){

          try{
            let res = await api.querySysAddressList()
            if (res.code == 0) {
                this.Addres = res.data
                    res.data.forEach(item=>{
                        if (item.defAddr) {
                            this.defPickUpCenter = item
                        }
                    })
                // 取料按钮事件

           }

           this.showBuy = true
          }catch{
           this.showBuy = false
          }


        },
      TobuyOrderParticulars(id){
          this.$router.push({
              name:"buyOrderParticulars",
              query:{order:id}
          })
        },
        checkBox(){
             //  声明变量用来存储重量和金额
                let weight = 0
                let money = 0
                this.orderList =[]
                this.checkList.forEach(item =>{
                    // 遍历复选框选中的数据 算法添加到变量中
                    weight += item.weight
                    money += item.money
                    // 订单单号
                    this.orderList.push(item.oid)
                })
            this.$emit('getData',{weight,money,orderList:this.orderList})
        },
        onLoad() {
            setTimeout(async() => {
               try{
                 let res = await api.getBuyOrderPriceList({
                    page:this.page,
                    size:this.size,
                    status:this.activeName})
               // 如果加载完数据了 last为true
                // 把上一段 number赋值给page
                this.page = res.number +1
                // 把数组里的每一项一一铺开添加
                this.list.push(...res.content)
                    if (this.list.length == 0) {
                        this.ishow = true
                     }else{
                        this.ishow = false
                     }
                // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
                // 这样才可以继续下次加载
                this.loading = false;
                if (res.last == true) {
                    this.finished = true;
                }
               }catch{
                    this.ishow = true
               }

            },1000)

        },
async   onRefresh() {
                this.finished = false;
                //请求最新的数据
                let res =  await api.getBuyOrderPriceList({
                    page:0,
                    size:this.size,
                    status:this.activeName})
                    this.page = res.number+1
                    this.list = res.content

                     if (this.list.length == 0) {
                        this.ishow = true
                     }else{
                        this.ishow = false
                     }
                        this.refreshing = false
                        this.loading = false
        },
},
async  mounted(){
    //  let res = await api.getAddress()
    //        if (res.length != 0) {
    //         res.forEach(item=>{
    //         if (item.defAddr) {
    //             this.ReceivingGoods = item
    //             this.isAddress = true
    //         }
    //        })

    //        }

}
};
</script>

<style lang="scss" scoped>
::v-deep{
    .van-loading__text{
        font-size: 30px;
    }
    .van-list__finished-text{
        font-size: 30px;
    }
    .van-pull-refresh__head{
      font-size:30px !important;
    }
    .van-cell__title {
      font-size: 28px;
      color: #242424;
    }
    .van-cell__value{
      font-size: 28px;
      color: #999;
    }
    .van-icon{
        font-size: 32px;
        line-height: 10px;
    }
    .van-radio__icon{
    .van-icon{
        font-size: 28px;
        line-height: 30px !important;
    }
    }
  .van-radio__icon--checked {
    .van-icon {
      background: $YellowFontColor !important;
      border-color: $YellowFontColor !important;
    }
  }
    .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
.van-dialog{
        width: 80%;
        .takeMode{
            position: relative;
            border-bottom: 1px solid #999999;
            padding: 20px 20px 0px 40px;
            .input{
                width: 30px;
                height: 50px;
                position: absolute;
                top: 14px;
                left: 0px;
                border-radius: 50%;
                .van-radio{
                    width: 100%;
                    height: 100%;
                    .van-radio__icon{
                        width: 100%;
                        height: 100%;
                    }
                    ::v-deep{
                        .van-icon{
                            font-size: 24px;
                            line-height: 30px !important;
                        }
                    }

                }
            }
        }
        .box{
            height: 100px;
            padding: 20px 20px 20px 40px;
            border-bottom: 1px solid #999999;

        }
        .custom-title{
            font-size: 36px;
        }
        .goHome{
                line-height: 60px !important;
            margin: 30px auto 0px !important;
        }
        .addres{
            margin: 30px auto 20px;
             .van-icon{
                color: $YellowFontColor;
                font-size: 44px !important;
                line-height: 60px !important;
            }
        }
        .popbtn{
            padding: 30px 50px 20px;
        }
        .gocer{
            width: 200px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 50px;
            //border: 1px solid #FF2D2E;
            font-size: 28px;
        }
  .closebtn{
    //background: #F1F3F7;
    color: $YellowFontColor;
    border: 1px solid $YellowFontColor;
  }
  .confirmbtn{
    background-color:$YellowFontColor ;
    color: $YellowHeadBackgroundColor;
  }
        .flex_between{
            display: flex;
            justify-content: space-between;
        }
}
.van-popup{
.title{
    width: 100%;
    font-size: 30px;
    padding: 10px 0;
    text-align: center;
    color: #999999;
}
.text{
    width: 100%;
    padding: 20px 14px;

    h4{
        padding: 10px 6px;
    }
    p{
        padding: 10px 6px;
        color: #999999;
    }
}
.bg{
    background-color: #FFF9EB;

}
}
.buyPrice{
    background-color: #f7f7f7;
    padding: 30px 40px 100px;
}
.border{
    padding: 6px 16px;
    color: #FF2D2E;
    border-radius: 50px;
}
.redBorder{
  background: #FFE6E6 !important;
  color: #F52621 !important;
}
.yellowBorder{
  color: $YellowFontColor !important;
  border: 1px solid $YellowFontColor !important;
}
.greenBorder{
  border: 1px solid #02AD3D !important;
  color: #02AD3D !important;
}
.commonBorder {
  width: 160px;
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  text-align: center;
  border-radius: 6px;
}
.blueBorder{
  background: #E6F4FF !important;
  color: #33A5FF !important;

}
.ashBorder{
  //background: #F1F3F7;
  color: $YellowFontCancelColor;
  border: 1px solid $YellowFontCancelColor;
}
.cell{
    background-color: #fff;
    // padding: 30px 20px 20px;
    border-radius: 12px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}
.order{
    width: 98%;
    padding: 30px 20px 20px;

}
.line{
    width: 8px;
    height: 450px;
    background-color: $YellowBarColor;
    border-radius: 8px 0px 0px 8px;
}
.flex-between{
    display: flex;
    justify-content: space-between;
    h3{
        color: #242424;
        font-size: 32px;
    }
    .xiangqing{
        color: #999999;
        font-size: 28px;
        .icon{
            width: 30px;
            height: 30px;
            background: url('~@/assets/images/btn__grey_more@2x.png') no-repeat;
            background-size: 100%;
            position: relative;
            top: 4px;
        }
    }
    .orderInfo{
        width: 400px;
        line-height: 40px;
        font-size: 24px;
    }
}
.flex{
    display: flex;
}
.padd{
    padding: 10px 0;
}
.info{
    margin: 20px 0 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
    .price{
        margin: 6px 0;
    }
}
.color7{
    color: #242424;
}
.colore1{
    color: #999999;
    width: 200px;
}
.size{
    font-size: 28px;
}
.icon{
    margin-right: 10px;
}

// .line{
//     width: 8px;
//     height: 400px;
// }
input[type="checkbox"]{
  -webkit-appearance: none;
    width:20px;
    height:20px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    border-radius: 50%;
    position: relative;
    top: 6px;
    margin-right: 20px;
    }
input[type="checkbox"]::before{
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    background: #ffffff;
    width:30px;
    height: 30px;
    border: 1px solid #e9e9e9;
    border-radius: 50%;}

input[type="checkbox"]:checked::before{
    content: "\2713";
    position: absolute;
    top: -6px;
    left: -6px;
    width:30px;
    height: 30px;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
  color: $YellowHeadBackgroundColor;
  background-color: $YellowFontColor;
    //line-height: 34px;
    font-size: 24px;
    font-weight: bold;
    }
</style>
