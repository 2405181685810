<template>
        <!-- 存料提纯 -->
  <div class="buyOrder">
         <van-tabs v-model="active" animated sticky type="card">
            <van-tab title="买料定价" name="buyPrice">
                 <div class="top">
                      <van-tabs v-model="activeName" animated sticky swipeable>
                          <van-tab 
                          v-for="(item,index) in name" 
                          :title="item == 'ALL'?'全部':item == 'SUSPENSE,WAIT_FETCH'?'进行中':'已完成'" 
                          :name="item"
                          :key="index">    
                    
                         <keep-alive>
                               <buyOrderPrice :activeName="activeName" @getData="getData" ref="buyOrder"/>  
                         </keep-alive>
                                        
                          </van-tab>
                      </van-tabs>
                 </div>
            </van-tab>
            <van-tab title="买料结算" name="buyJieSuan">
                    <buyOrderJieSuan />
            </van-tab>
         </van-tabs>
    <!-- 右侧结算 -->
<!--    <div class="tabsRight" @click="tobreakcontract">
      <span>结算</span>
      <van-icon name="arrow" slot="right" />
    </div>-->
         <!-- 左侧图标 -->
         <div class="tabsLeftIcon">
            <van-icon name="arrow-left" @click="leftIcon"/>
        </div>
        <footer v-if="active == 'buyPrice'">
            <div class="left">
                <div class="leftText">重量 : {{weight?weight:0}}g</div>
                <div class="rightText">金额 : ¥{{money?money:0}}元</div>
            </div>
            <div class="right" @click="takeMaterial">取料</div>
        </footer>

  </div>
</template>

<script>
import buyOrderPrice from './components/buyOrderPrice.vue'
import buyOrderJieSuan from './components/buyOrderJieSuan.vue'
export default {
    
    components:{
        buyOrderPrice,
        buyOrderJieSuan
    },
    data() {
        return {
            active:'buyPrice',
            name:['ALL','SUSPENSE,WAIT_FETCH','DONE'],
            activeName:'ALL',
            weight:'',
            money:''
        }
    },
    activated() {
        this.$route.query.active?this.active =  this.$route.query.active:this.active = 'buyPrice'
        
    },
    methods:{
            // 左侧图标事件
        leftIcon(){
            // console.log(this.$route);
            this.$router.push({
                name:'person'
            })
        },
            // 取料按钮事件
        takeMaterial(){
            if (this.weight && this.money) {
                // console.log(this.$refs.buyOrder);
                // 选择取料的订单
              if (this.activeName === 'ALL') {
                this.$refs.buyOrder[0].confirmTakeMaterial()
              } else {
                this.$refs.buyOrder[1].confirmTakeMaterial()
              }
                    
            }else{
                // 未选择取料的订单
                this.$toast('请选择需要提料的订单')
            }
        },
            getData(item){
                this.weight = item.weight
                this.money = item.money
                console.log(item);
            },
        tobreakcontract(){
          this.$router.push({
            name:'breakContract',
            query:{
              active:'buy',
              path: "buyOrder"
            }
          })
        }
    },
}
</script>

<style lang="scss" scoped>
.buyOrder{
    background-color: #f7f7f7;
}
::v-deep{
   
    .van-tabs__wrap{
        height: 88px;
        padding: 0 220px;
      background-color: $YellowHeadBackgroundColor;
    }
    .van-tabs__line{
        display: none;
    }
    .van-tabs__nav--line {
        padding-bottom: 0 !important;
}
    .van-tabs__nav{
        height: 60px;
        margin-top: 16px;
        border-radius: 10px ;
    }
    .van-tab{
        height: 100%;
        line-height: 50px;
        font-size: 28px;
        padding: 0;
      color: $YellowFontColor;
      border: 1px solid $YellowFontColor !important;
    }
  .van-tabs__nav--card .van-tab--active {
    background:  $YellowFontColor !important;
  }
  .van-tabs__nav--card .van-tab {
    //border-right: none;
  }
  .van-tabs__nav--card {
    border: none;
    border-radius: 100px;
    padding: 2px;
  }
  .van-tabs__line {
    background:  $YellowFontColor !important;
    height: 4px;
  }

        .top{
        .van-tabs__wrap{
        height: 80px;
        padding: 0;
        background-color: #fff;
    }
    .van-tabs__line{
        display: block;
        bottom:0;
        height: 4px;
    }
    .van-tabs__nav--line {
        margin: 0;
        padding-bottom: 0 !important;
        background-color: #fff;
}
    .van-tabs__nav{
        border: none;
        height: 80px;
    }
    .van-tab{
      border: none !important;
        background-color: #fff;
        height: 100%;
        color: #999999;
        line-height: 70px;
        font-size: 26px;
        padding: 0;
    }
    .van-tab--active{
        background-color: #fff;
      color:  $YellowHeadColor !important;
    }
    
    }}
.tabsLeftIcon{
    position: fixed;
    top: 30px;
    left: 10px;
    z-index: 9999;
    .van-icon{
        font-size: 32px;
      color: $YellowHeadColor;
    }
}
footer{
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100px;
    .left{
        width: 70%;
        background-color: $YellowDepositTuiColor;
        padding-left: 40px;
        display: flex;
        color: $YellowBarColor;
        font-size: 32px;
        .rightText{
            margin-left: 30px;
        }
        div{
            line-height: 100px;
        }

    }
    .right{
        width: 30%;
        background-color: $YellowFontColor;
        font-size: 32px;
        text-align: center;
        line-height: 100px;
        color: $YellowHeadBackgroundColor;
    }
}
.tabsRight {
  position: fixed;
  z-index: 9999;
  top: 18px;
  right: 30px;
  height: 50px;
  font-size: 28px;
  line-height: 50px;
  color: #f6d0ab;
  i {
    top: 4px;
  }
}
</style>